import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/Components/ui/dialog";
import { cn } from "@/lib/utils";
import { useCallback } from "react";

export default function Modal({ title = "", children, headExtra, noHeader = false, show = false, className = "", closable = true, onClose = () => { } }) {

    const close = useCallback(() => closable && onClose(), [closable, onClose]);

    return (
        <Dialog open={show} onOpenChange={close}>
            <DialogContent closable={closable} className={cn("isolate flex flex-col", className)}>
                {!noHeader && <DialogHeader className="h-[40px] w-full flex flex-row justify-between items-center gap-4">
                    <DialogTitle>{title}</DialogTitle>
                    {headExtra}
                </DialogHeader>}
                {children}
            </DialogContent>
        </Dialog>
    )
}

